import React from 'react';
import { Link } from 'react-router-dom';

import Navbar from '../../component/Navbar/navbar';
import Footer from '../../component/Footer/footer';
import BlogUserDetail from '../../component/blogUserDetail';
import { blogData } from '../../data/data';

import {
  MdKeyboardArrowRight,
  MdOutlineKeyboardArrowLeft,
  FaArrowRight,
} from '../../assets/icons/icons';

export default function BlogSidebar() {
  return (
    <>
      <Navbar navClass="nav-light" />
      <section className="relative table w-full py-32 lg:py-36 bg-[url('../../assets/images/blog/bg.jpg')] bg-center bg-no-repeat bg-cover">
        <div className="absolute inset-0 bg-black opacity-80"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">
              Blogs & News
            </h3>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
            <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/index">Exemplar Info</Link>
            </li>
            <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <MdKeyboardArrowRight className="text-xl" />
            </li>
            <li
              className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Blogs
            </li>
          </ul>
        </div>
      </section>

      <div className="relative">
        <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto scale-[2.0] origin-top"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
            <div className="lg:col-span-8 md:col-span-6">
              <div className="grid lg:grid-cols-2 grid-cols-1 gap-[30px]">
                {blogData.slice(9, 18).map((item, index) => {
                  return (
                    <div
                      className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden"
                      key={index}
                    >
                      <img src={item.image} alt="" />

                      <div className="content p-6">
                        <Link
                          to={`/blog-detail/${item.id}`}
                          className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out"
                        >
                          {item.desc}
                        </Link>
                        <p className="text-slate-400 mt-3">{item.desc}</p>

                        <div className="mt-4">
                          <Link
                            to={`/blog-detail/${item.id}`}
                            className="relative inline-flex  items-center font-normal tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500"
                          >
                            Read More{' '}
                            <FaArrowRight className="ms-2 text-[10px]" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                <div className="md:col-span-12 text-center">
                  <nav>
                    <ul className="inline-flex items-center -space-x-px">
                      <li>
                        <Link
                          to="/#"
                          className="size-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-lg hover:text-white border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <MdOutlineKeyboardArrowLeft className="text-[20px] rtl:rotate-180 rtl:-mt-1" />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/#"
                          className="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          1
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/#"
                          className="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          2
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/#"
                          aria-current="page"
                          className="z-10 size-[40px] inline-flex justify-center items-center text-white bg-indigo-600 border border-indigo-600"
                        >
                          3
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/#"
                          className="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          4
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/#"
                          className="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          5
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/#"
                          className="size-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-lg hover:text-white border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <MdKeyboardArrowRight className="text-xl rtl:rotate-180 rtl:-mt-1" />
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

            <BlogUserDetail className={'lg:col-span-4 md:col-span-6'} />
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="md:flex justify-center">
            <div className="lg:w-2/3 text-center">
              <h3 className="md:text-3xl text-2xl md:leading-normal leading-normal font-semibold mb-6">
                Subscribe our weekly subscription
              </h3>

              <p className="text-slate-400 max-w-xl mx-auto">
                Add some text to explain benefits of subscripton on your
                services. We'll send you the best of our blog just once a
                weekly.
              </p>

              <div className="mt-8">
                <div className="text-center subcribe-form">
                  <form className="relative mx-auto max-w-xl">
                    <input
                      type="email"
                      id="subemail"
                      name="name"
                      className="pt-4 pe-40 pb-4 ps-6 w-full h-[50px] outline-none text-black dark:text-white rounded-full bg-white/70 dark:bg-slate-900/70 border border-gray-100 dark:border-gray-700"
                      placeholder="Enter your email id.."
                    />
                    <button
                      type="submit"
                      className="py-2 px-5 inline-block font-semibold tracking-wide align-middle transition duration-500 ease-in-out text-base text-center absolute top-[2px] end-[3px] h-[46px] bg-indigo-600 hover:bg-indigo-700 border border-indigo-600 hover:border-indigo-700 text-white rounded-full"
                    >
                      Subcribe Now
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
